/* styles/fonts.css */
@font-face {
  font-family: 'MyFont';
  src: url('./font/Sansation_Regular.ttf') format('woff2'),
    url('./font/Sansation_Light.ttf') format('woff2'),
    url('./font/Sansation_Bold.ttf') format('woff2');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'MyFont', sans-serif;
  scroll-behavior: smooth;
}
.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

:root {
  --swiper-pagination-color: #b4b4b4 !important;
  --swiper-pagination-bullet-inactive-opacity: 10 !important;
  --swiper-navigation-size: 20px !important;
  font-weight: 600;

  /* --swiper-theme-color: #014470 !important; */
}
@media (min-width: 768px) {
  :root {
    --swiper-navigation-size: 40px !important;
  }
}
/* button.swiper-pagination-bullet {
  background: ;
} */
.swiper-pagination-bullet {
  background-color: #b4b4b4 !important;
  opacity: 0px !important;
}
.swiper-pagination-bullet-active {
  background-color: #007aff !important;
}

.font {
  font-family: 'Satoshi', sans-serif;
}
@media (min-width: 1280px) {
  .swiper-button-prev {
    margin-top: -20px !important;
  }
}

@media (max-width: 767px) {
  .swiper-button-prev {
    margin-top: -8px !important;
  }
}
